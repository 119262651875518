import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { PortalModule } from "@angular/cdk/portal";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { MainNavComponent } from "./templates/main-nav/main-nav.component";
import { AdminResetDialogueComponent } from "./components/admin-widgets/admin-reset-dialogue/admin-reset-dialogue.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSliderModule } from "@angular/material/slider";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { LogoutButtonComponent } from "./components/global-widgets/logout-button/logout-button.component";
import { SessionStatusComponent } from "./components/global-widgets/session-status/session-status.component";
import { UserConfigButtonComponent } from "./components/global-widgets/user-config-button/user-config-button.component";
import { UserOptionsDialogueComponent } from "./components/global-widgets/user-options-dialogue/user-options-dialogue.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { WelcomePageComponent } from "./templates/welcome-page/welcome-page.component";
import { DriverPageComponent } from "./pages/driver-page/driver-page.component";
import { ViewerPageComponent } from "./pages/viewer-page/viewer-page.component";
import { EnergiesTableComponent } from "./components/global-widgets/energies-table/energies-table.component";
import { RaceManagerPageComponent } from "./pages/race-manager-page/race-manager-page.component";
import { AlertsPopupComponent } from "./components/global-widgets/alerts-popup/alerts-popup.component";
import { EnergiesPageComponent } from "./pages/individual-viewer-component-pages/energies-page/energies-page.component";
import { ImportPageComponent } from "./pages/import-page/import-page.component";
import { RacePlotComponent } from "./components/global-widgets/race-plot/race-plot.component";

import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";
import * as more from "highcharts/highcharts-more.src";
import * as solidGauge from "highcharts/modules/solid-gauge.src";
import { LoadSessionPageComponent } from "./pages/load-session-page/load-session-page.component";
import * as Sentry from "@sentry/angular-ivy";
import { RaceplotPageComponent } from "./pages/individual-viewer-component-pages/raceplot-page/raceplot-page.component";
import { TestPageComponent } from "./pages/test-page/test-page.component";
import { MapComponent } from "./components/global-widgets/map/map.component";

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    MainNavComponent,
    AdminResetDialogueComponent,
    LogoutButtonComponent,
    SessionStatusComponent,
    UserConfigButtonComponent,
    UserOptionsDialogueComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    WelcomePageComponent,
    DriverPageComponent,
    ViewerPageComponent,
    EnergiesTableComponent,
    RaceManagerPageComponent,
    AlertsPopupComponent,
    EnergiesPageComponent,
    ImportPageComponent,
    RacePlotComponent,
    LoadSessionPageComponent,
    RaceplotPageComponent,
    TestPageComponent,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PortalModule,
    AppRoutingModule,
    MatMenuModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatBadgeModule,
    MatSliderModule,
    MatTableModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DragDropModule,
    ChartModule,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, solidGauge] },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        /* */
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AdminResetDialogueComponent],
})
export class AppModule {}
