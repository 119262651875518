import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
const axios = require("axios");
import { urls } from "../../../environments/environment";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private snackBar: MatSnackBar, private router: Router) {
    this.loadOptions();
    this.updateConfigs();
    this.updateSTConfigs();
  }

  isAuthenticated = false;
  user: any;
  role: any;
  teamName: any;
  userOptions = { theme: "Jaguar", favouriteDriver: "9" };
  apiConfig: any;
  wsConfig: any;
  stAPIConfig: any;
  stWSConfig: any;

  setJwt(jwt: string) {
    localStorage.setItem("jwt", jwt);
  }

  getJwt() {
    return localStorage.getItem("jwt");
  }

  deleteJwt() {
    localStorage.removeItem("jwt");
  }

  setSTJwt(jwt: string) {
    localStorage.setItem("jwt-st", jwt);
  }

  getSTJwt() {
    return localStorage.getItem("jwt-st");
  }

  deleteSTJwt() {
    localStorage.removeItem("jwt-st");
  }

  loadOptions() {
    let localStorageObj = localStorage.getItem("userOptions");
    if (localStorageObj == "undefined") {
      localStorageObj = null;
    }
    let userOptions: any;
    if (localStorageObj !== null) {
      userOptions = JSON.parse(localStorageObj);
    } else {
      userOptions = null;
    }
    if (userOptions) {
      if (this.isThemeValid(userOptions["theme"])) {
        this.userOptions["theme"] = userOptions["theme"];
      } else {
        this.userOptions["theme"] = "Jaguar";
      }
      if (userOptions["favouriteDriver"]) {
        this.userOptions["favouriteDriver"] = userOptions["favouriteDriver"];
      } else {
        this.userOptions["favouriteDriver"] = "9";
      }
    }
  }

  setOptions(userOptions: any) {
    this.userOptions = userOptions;
    localStorage.setItem("userOptions", JSON.stringify(userOptions));
  }

  isThemeValid(theme: any) {
    return ["Jaguar", "Envision"].includes(theme);
  }

  authenticatePwd(username: string, password: string) {
    if (username === "" || password === "") {
      this.snackBar.open("Missing fields", "", {
        duration: 2000,
      });
    } else {
      username = username.toLowerCase();
      let loginData = {
        username: username,
        password: password,
      };
      axios
        .post(urls["st"] + "api/auth/login", loginData)
        .then((resp: { status: any; data: { userOptions: any } }) => {
          if (resp.status == 200) {
            this.setOptions(resp.data.userOptions);
            this.setAuthData(resp);
            this.updateConfigs();
            this.setSTAuthData(resp);
            this.updateSTConfigs();
            this.router.navigateByUrl("/home");
          }
        });
    }
  }

  authenticateJwt() {
    return new Promise((resolve, reject) => {
      let userToken = this.getJwt();
      if (userToken) {
        let loginData = {
          token: userToken,
        };
        axios
          .post(urls["server"] + "api/auth/login/", loginData)
          .then((resp: { status: number }) => {
            if (resp.status == 200) {
              this.setAuthData(resp);
              resolve(true);
            }
          })
          .catch((e: any) => {
            this.logout();
            reject(e);
          });
      } else {
        this.logout();
        reject();
      }
    });
  }

  setAuthData(resp: any) {
    if (resp.data.hasOwnProperty("token")) {
      this.setJwt(resp.data.token);
    }
    Sentry.setUser({ username: resp.data.username });
    this.isAuthenticated = true;
    this.user = resp.data.username;
    this.role = resp.data.role;
    this.teamName = resp.data.teamName;
  }

  setSTAuthData(resp: any) {
    if (resp.data.hasOwnProperty("token")) {
      this.setSTJwt(resp.data.token);
    }
  }

  logout() {
    this.deleteJwt();
    Sentry.setUser(null);
    this.isAuthenticated = false;
    this.user = undefined;
    this.role = undefined;
    this.router.navigateByUrl("/login");
  }

  updateConfigs() {
    let jwt = this.getJwt();
    let authHeader = `Bearer ${jwt}`;
    this.apiConfig = this.createConfig(authHeader);
    this.wsConfig = this.createConfigWebsocket(authHeader);
  }

  updateSTConfigs() {
    let jwt = this.getSTJwt();
    let authHeader = `Bearer ${jwt}`;
    this.stAPIConfig = this.createConfig(authHeader);
    this.stWSConfig = this.createConfigWebsocket(authHeader);
  }

  createConfig(authHeader: string) {
    return {
      headers: { authorization: authHeader },
    };
  }

  createConfigWebsocket(authHeader: string) {
    return {
      extraHeaders: {
        Authorization: authHeader,
      },
    };
  }
}
